import { ConfigContext } from "@/context/ConfigContext";
import getToken from "@/general/token-retriever";
import formatFileSize from "@/general/utilities/FileSizeStringFormatter";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios from "axios";
import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import useSWR from "swr";
import './ProfilePicture.scss';
import { MAX_FILE_SIZE } from "@/general/constants";

type ProfilePictureProps = {
    editable?: boolean;
    width?: string;
    height?: string;
    iconSize?: string;
};

function ProfilePicture({ editable = false, width = "100%", height = "100%", iconSize = "fa-md" }: ProfilePictureProps) {

    const configContext = useContext(ConfigContext);
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const imageRef = useRef(null);
    const fileInputRef = useRef(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [uploading, setUploading] = useState(false);
    const { data, isLoading, error, mutate } = useSWR(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.picture : null, { shouldRetryOnError: false });

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (data !== undefined && data.size > 0) {
            const imageObjectURL = URL.createObjectURL(data);
            setProfilePicture(imageObjectURL);
        }
    }, [data]);

    const handleFileChange = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];

        if (!file.type.startsWith('image/')) {
            toast.error(t('profile:toasts:uploadProfilePictureInvalidFileType'));
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            toast.error(t('profile:toasts:validationMaxFileSize', { fileSize: formatFileSize(file.size), maxFileSize: formatFileSize(MAX_FILE_SIZE), fileName: file.name }));
            return;
        }

        const endpoint = configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.picture;
        const token = await getToken(msalContext, configContext);
        setUploading(true);

        await axios.post(endpoint, file, {
            headers: {
                'Authorization': token,
                'Content-Type': file.type,
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey,
            }
        })
            .then((response) => {
                setProfilePicture(URL.createObjectURL(file));
                mutate();
                toast.success(t('profile:toasts:uploadProfilePictureSuccess'));
                return response.data;
            })
            .catch(error => {
                toast.error(t('profile:toasts:uploadProfilePictureFailed'));
                console.error('Error uploading image:', error);
            })
            .finally(() => {
                setUploading(false);
            });
    };

    return (
        <div className={`profile-picture ${editable ? 'profile-picture--editable' : ''}`} style={{ width: width, height: height }}>
            {(isLoading || uploading) &&
                <Skeleton height={height} width={width} style={{lineHeight: 'inherit'}} circle={true} containerClassName="profile-picture__loading h-100" />
            }

            {(!isLoading && !uploading) && profilePicture &&
                <div className={`profile-picture__wrapper`}>
                    <div className="profile-picture__bg" style={{ backgroundImage: `url(${profilePicture})` }}></div>
                    <div className="profile-picture__image" style={{ width: width, height: height }}>
                        <img ref={imageRef} src={profilePicture} alt="Profile picture of the current logged-in user" />
                    </div>
                </div>
            }

            {(!isLoading && !uploading) && !profilePicture &&
                <div className="profile-picture__placeholder" style={{ width: width, height: height }}>
                    <img src={'/assets/img/WIHV_3D_Visual_User_Icon.jpg'} alt="Profile picture placeholder" />
                </div>
            }

            {(!isLoading && !uploading) && editable &&
                <div className={`profile-picture__upload-button`}>
                    <label htmlFor='file-input' tabIndex={0} onKeyDown={handleKeyDown}>
                        {t('profile:profilePictureUpload')}
                    </label>
                    <input
                        ref={fileInputRef}
                        id="file-input"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="file-input"
                    />
                </div>
            }
        </div>
    );

}

export default ProfilePicture;
