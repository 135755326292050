import React from "react";

export interface IConfigContext {
    configLoaded: boolean
    configBody?: IConfigBody
}

export interface IConfigBody {
    ad: any
    api: APIConfig
    logging: ILoggingConfig
    environment: IEnvironmentConfig
}

export interface ILoggingConfig {
    aiConnectionString: string
}

export interface IEnvironmentConfig {
    shortName: string
}

export interface APIConfig {
    baseUrl: string
    publicBaseUrl: string
    apiKey: string
    endpoints: APIEndpointConfig,
    scopes: string[]
}

export interface APIEndpointConfig {
    profile: {
        detail: string
        picture: string
        download: string
        completionRequirements: string
    }
    uploads: {
        list: string
        detail: string
    }
    attributes: {
        list: string
        detail: string
        feedback: string
    }
    feedbacks: {
        list: string
        detail: string
        validate: string
    }
    measurements: {
        list: string
        detail: string
        answers: string
        results: string
        categories: string
        recommended: string
        favourites: string
        favourite: string
        unfavourite: string
    }
    professions: {
        list: string
        detail: string
        categories: string
        recommended: string
        favourites: string
        favourite: string
        unfavourite: string

    }
    learning: {
        list: string
        categories: string
        recommended: string
        favourites: string
        favourite: string
        unfavourite: string
    }
    learningPaths: {
        list: string
        detail: string
        profession: string
    }
    learningCourses: {
        list: string
        detail: string
    }
    general: {
        translations: string
        settings: string
    }
    vouchers: {
        redeem: string
    }
    search: {
        escoProfessions: string
        professions: string
        learningResources: string
        interests: string
        volunteerExperiences: string
    }
}

export const ConfigContext = React.createContext<IConfigContext>({ configLoaded: false });