import { MeasurementDefinition } from "@/measurements/model/measurement-definitions/measurement-definition";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import FavoriteMeasurementToggle from "../favorite-toggle/FavoriteMeasurementToggle";
import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers"; // New import added
import './MeasurementCard.scss';
import {AttributeCategory} from "@/general/model/attribute-category";

type MeasurementCardProps = {
    measurement: MeasurementDefinition;
}

function MeasurementCard({ measurement }: MeasurementCardProps) {

    const isAuthenticated = useIsAuthenticated();
    const isCompleted = !isNaN(Date.parse(measurement?.completed || ''));
    const { translateAttributeCategory } = useAttributeCategoryTranslation(); // New line added

    return (
        measurement &&
        <Link to={`/measurements/${measurement.id}`} className={`measurement-card ${measurement?.isPublic ? 'public' : 'locked'}`}>

            <div className="img">
                <img src={measurement.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />
            </div>

            {isAuthenticated &&
                <div className="favorite">
                    <FavoriteMeasurementToggle measurement={measurement} />
                </div>
            }

            <div className='content'>
                <h2 className='title'>{measurement.title}</h2>
                <div className="meta">
                    {isCompleted &&
                        <span className="meta-item">{t('measurements:overview:measurementCardMetaCompleted')}</span>
                    }
                    <span className="meta-item">{measurement.durationInMinutes} {t('measurements:overview:measurementCardMetaMinutes')}</span>

                    { measurement.attributeCategory != 0 && 
                        <span className="meta-item">{translateAttributeCategory(measurement.attributeCategory)}</span>
                    }
                </div>
                <p className='description'>{measurement.description}</p>
                {isCompleted &&
                    <div className="results-link">
                        <Link to={`/measurements/${measurement.id}/results`}>
                            {t('measurements:overview:measurementCardResults')}
                            <i className='icon fa-solid fa-chevron-right ms-2 fa-sm' />
                        </Link>
                    </div>
                }
            </div>

            {!measurement?.isPublic &&
                <div className="locked-overlay">
                    <i className='icon fa-solid fa-lock fa-2x' />
                </div>
            }
        </Link>
    )
}

export default MeasurementCard
