import { ConfigContext } from '@/context/ConfigContext';
import { ProfileCompletionRequirements } from '@/general/model/profile-completion-requirements';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';

/**
 * Calculates core completion metrics given a list of requirements.
 */
const calcCompletionStats = (requirements: Array<{ isRequired: boolean; completed: boolean }>) => {
    let totalRequired = 0;
    let totalOptional = 0;
    let completedRequired = 0;
    let completedOptional = 0;

    requirements.forEach((r) => {
        if (r.isRequired) {
            totalRequired += 1;
            if (r.completed) completedRequired += 1;
        }
    });

    const requiredCompletion = totalRequired
        ? Math.round((completedRequired / totalRequired) * 100)
        : 0;

    requirements.forEach((r) => {
        if (r.isRequired === false) {
            totalOptional += 1;
            if (r.completed && requiredCompletion === 100) completedOptional += 1;
        }
    });

    const optionalCompletion = totalOptional
        ? Math.round((completedOptional / totalOptional) * 100)
        : 0;

    return {
        totalRequired,
        totalOptional,
        completedRequired,
        completedOptional,
        requiredCompletion,
        optionalCompletion,
    };
};

/**
 * Given required/optional completions and totals, returns an array of "phases".
 */
const getPhases = (
    requiredCompletion: number,
    optionalCompletion: number,
    totalRequired: number,
    totalOptional: number
) => {
    const total = totalRequired + totalOptional;
    const maxPercentageRequired = total
        ? Math.round((totalRequired / total) * 100)
        : 0;
    const maxPercentageOptional = total ? 100 : 0;

    return [
        {
            label: 'Required Requirements',
            minPercentage: 0,
            maxPercentage: maxPercentageRequired,
            completion: requiredCompletion,
        },
        {
            label: 'Optional Requirements',
            minPercentage: maxPercentageRequired,
            maxPercentage: maxPercentageOptional,
            completion: optionalCompletion,
        },
    ];
};

/**
 * Calculates percentages for a single category.
 */
const calculateCategoryPercentages = (category: { requirements: any[] }) => {
    const {
        totalRequired,
        totalOptional,
        completedRequired,
        completedOptional,
        requiredCompletion,
        optionalCompletion,
    } = calcCompletionStats(category.requirements || []);

    // Ensure optional tasks remain incomplete if required tasks are not fully completed
    const currentCompletion = Math.round(
        ((completedRequired + completedOptional) / (totalRequired + totalOptional)) * 100
    );
    
    const phases = getPhases(
        requiredCompletion,
        optionalCompletion,
        totalRequired,
        totalOptional
    );

    return {
        totalRequired,
        totalOptional,
        completedRequired,
        completedOptional,
        requiredCompletion,
        optionalCompletion,
        currentCompletion,
        phases,
    };
};

/**
 * Calculates overall percentages across all categories.
 */
const calculateOverallPercentages = (categories: Array<{ requirements: any[] }>) => {
    const allRequirements = categories.flatMap((cat) => cat.requirements);
    const {
        totalRequired,
        totalOptional,
        completedRequired,
        completedOptional,
        requiredCompletion,
        optionalCompletion,
    } = calcCompletionStats(allRequirements);

    // Ensure optional tasks remain incomplete if required tasks are not fully completed
    const currentCompletion = Math.round(
        ((completedRequired + completedOptional) / (totalRequired + totalOptional)) * 100
    );
    
    const phases = getPhases(
        requiredCompletion,
        optionalCompletion,
        totalRequired,
        totalOptional
    );

    return {
        totalRequired,
        totalOptional,
        completedRequired,
        completedOptional,
        requiredCompletion,
        optionalCompletion,
        currentCompletion,
        phases,
    };
};

const useProfileCompletionRequirements = () => {
    const { configBody } = useContext(ConfigContext);
    const isAuthenticated = useIsAuthenticated();

    const fetchUrl = isAuthenticated
        ? configBody.api.baseUrl + configBody.api.endpoints.profile.completionRequirements
        : null;

    const {
        data: profileCompletionRequirements,
        isLoading,
        error,
        mutate,
    } = useSWR<ProfileCompletionRequirements>(fetchUrl);

    // Overall stats across all categories
    const categories = profileCompletionRequirements?.requirementCategories || [];
    const overallStats = calculateOverallPercentages(categories);

    return {
        profileCompletionRequirements,
        totalCompletion: overallStats.currentCompletion,
        // "profileCompleted" means 100% on both required & optional
        profileCompleted:
            overallStats.requiredCompletion === 100 &&
            overallStats.optionalCompletion === 100,
        optionalCompleted: overallStats.optionalCompletion === 100,
        requiredCompleted: overallStats.requiredCompletion === 100,
        phases: overallStats.phases,
        isLoading,
        error,
        mutate,

        // Expose the per-category function for any consumer that needs it
        calculateCategoryPercentages,
    };
};

export default useProfileCompletionRequirements;
