import React, { CSSProperties } from "react";

interface Phase {
    label: string;
    minPercentage: number; // Start of this phase's range
    maxPercentage: number; // End of this phase's range
    completion: number; // Unfilled portion color
}

interface ProfileProgressBarProps {
    currentStep: number;
    maxSteps: number;
    phases: Phase[];
    className?: string;
    showTooltips?: boolean; // New prop to control tooltip visibility
}

export default function ProfileProgressBar({
                                               currentStep,
                                               maxSteps,
                                               phases,
                                               className,
                                               showTooltips = true, // Default value for tooltips is true
                                           }: ProfileProgressBarProps) {
    const progress = Math.min((currentStep / maxSteps) * 100, 100);
    
    /**
     * Get the percentage of the unfilled part for the phase.
     */
    const getUnfilledPercentage = (phase: Phase) => {
        if (progress <= phase.minPercentage) return 100;
        if (progress >= phase.maxPercentage) return 0;
        const range = phase.maxPercentage - phase.minPercentage;
        const remaining = phase.maxPercentage - progress;
        return Math.ceil((remaining / range) * 100); // Rounding up
    };

    return (
        <div style={styles.wrapper} className={className}>
            {/* Tooltips */}
            {showTooltips && (
                <div style={styles.tooltipsContainer}>
                    {phases.map((phase, index) => {
                        if (index === phases.length - 1) return null; // Skip "Compleet" tooltip
                        if (phases[0].maxPercentage === 100) return null; // Skip "Vereist" tooltip if none optional requirements

                        return (
                            <div
                                key={index}
                                style={{
                                    ...styles.tooltip,
                                    left: `${phase.maxPercentage}%`,
                                    transform: "translateX(-50%)",
                                    textAlign: "center",
                                }}
                            >
                                Vereist
                            </div>
                        );
                    })}
                </div>
            )}
            
            <div 
                style={{
                    ...styles.barContainer,
                    background: currentStep !== maxSteps ? "linear-gradient(to right, #fe7224, #41b883)" : "#41b883",
                }}
            >
                {/* Map over phases */}
                {phases.map((phase, index) => {
                    const phaseWidth = `${phase.maxPercentage - phase.minPercentage}%`;
                    const unfilledPercent = getUnfilledPercentage(phase);
                    const unfilledWidth = `${unfilledPercent}%`;

                    return (
                        <div
                            key={index}
                            className={`phase-${index}`}
                            style={{
                                ...styles.phaseOuter,
                                width: phaseWidth,
                            }}
                        >
                            {/* Unfilled portion */}
                            <div
                                className={`phase-${index}`}
                                style={{
                                    ...styles.phaseUnfilled,
                                    width: unfilledWidth,
                                    backgroundColor: "#e9ecef", // Unfilled portion color
                                    borderRight: index === 0 ? "2px #ffffff solid" : "none",
                                }}
                            />
                        </div>
                    );
                })}

                {/* Transparent overlay for the filled part */}
                <div
                    style={{
                        ...styles.phaseFill,
                        width: `${progress}%`,
                    }}
                />

                {/* Centered percentage */}
                <div
                    style={{
                        ...styles.centeredText,
                        left: `calc(${progress}% / 2)`, // Center within the filled portion
                        transform: progress > 5 ? "translate(-50%, -50%)" : "translate(50%, -50%)", // Keep it centered horizontally and vertically
                    }}
                >
                    {Math.round(progress)}%
                </div>
            </div>
        </div>
    );
}

/* ------------------- STYLES ------------------- */

const styles: Record<string, CSSProperties> = {
    wrapper: {
        position: "relative",
        margin: "20px 0",
    },
    tooltipsContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
    },
    tooltip: {
        position: "absolute",
        bottom: "0px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "rgba(238, 238, 238, 0.1764705882)",
        color: "#333",
        padding: "4px 8px",
        borderRadius: "16px",
        whiteSpace: "nowrap",
        border: "var(--border-width-m) var(--color-neutral-light-300) solid",
    },
    barContainer: {
        display: "flex",
        height: "32px",
        overflow: "hidden",
        position: "relative",
        clipPath: "inset(1px round 16px)",
        borderRadius: "16px",
        backfaceVisibility: "hidden",
        transform: "translateZ(0)",
    },
    phaseOuter: {
        position: "relative",
        display: "flex",
        height: "100%",
    },
    phaseUnfilled: {
        position: "absolute",
        height: "100%",
        top: 0,
        right: 0,
    },
    phaseFill: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        transition: "width 0.3s ease",
    },
    centeredText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#fff", // White text for better contrast
        zIndex: 10, // Ensure text is above all other elements
    },
};
