import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import { t } from "i18next";
import Skeleton from "react-loading-skeleton";
import DashboardTile from "./DashboardTile";
import PhasedProgressBar from "./PhasedProgressBar"; // Import the new PhasedProgressBar component
import "./ProfileRequirementCompletionTile.scss";
import React from "react";

type ProfileRequirementCompletionCardProps = {
    tileClassName?: string;
};

function ProfileRequirementCompletionTile({
                                              tileClassName,
                                          }: ProfileRequirementCompletionCardProps) {
    const { totalCompletion, isLoading, phases } = useProfileCompletionRequirements();

    // Determine which text block to show based on progress
    const renderTextBlock = () => {
        if (totalCompletion < phases[0].maxPercentage) {
            const remaining = Math.max(0, phases[0].maxPercentage - totalCompletion); // Remaining percentage for phase 1
            return (
                <div className="profile-text">
                    <h4>{t("dashboard:profileRequirementCompletion:insufficientData:title")}</h4>
                    <p>{t("dashboard:profileRequirementCompletion:insufficientData:description", { remaining })}</p>
                </div>
            );
        } else if (totalCompletion < phases[1].maxPercentage) {
            const remaining = Math.max(0, phases[1].maxPercentage - totalCompletion); // Remaining percentage for phase 2
            return (
                <div className="profile-text">
                    <h4>{t("dashboard:profileRequirementCompletion:minimumRequiredData:title")}</h4>
                    <p>{t("dashboard:profileRequirementCompletion:minimumRequiredData:description", { remaining })}</p>
                </div>
            );
        } else {
            return (
                <div className="profile-text">
                    <h4>{t("dashboard:profileRequirementCompletion:completeProfile:title")}</h4>
                    <p>{t("dashboard:profileRequirementCompletion:completeProfile:description")}</p>
                </div>
            );
        }
    };

    return (
        <DashboardTile
            tileClassName={`${tileClassName}`}
            contentClassName="profile-requirement-completion-tile-content"
        >
            <h2 className="title">{t("dashboard:profileRequirementCompletion:title")}</h2>

            <div className="profile-progress">
                {isLoading ? (
                    <Skeleton
                        height={24}
                        style={{ borderRadius: "var(--border-radius-round)" }}
                    />
                ) : (
                    // Use PhasedProgressBar with dynamic phases
                    <PhasedProgressBar
                        currentStep={totalCompletion}
                        maxSteps={100}
                        phases={phases}
                    />
                )}
            </div>

            {/* Render the dynamic text block */}
            {!isLoading && renderTextBlock()}
        </DashboardTile>
    );
}

export default ProfileRequirementCompletionTile;
