import PhasedProgressBar from "./PhasedProgressBar"; // Import the new PhasedProgressBar component
import WihvLoader from "@/components/loading-component/WihvLoader";
import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers";
import { AttributeCategory } from "@/general/model/attribute-category";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import { t } from "i18next";
import DashboardTile from "./DashboardTile";
import "./ProfileRequirementCategoryCompletionTile.scss";
import React from "react";
import RequirementTaskList from "@/dashboard/components/RequirementTaskList";

type ProfileRequirementCategoryCompletionTileProps = {
    attributeCategory: AttributeCategory;
    tileClassName?: string;
};

function ProfileRequirementCategoryCompletionTile({ attributeCategory, tileClassName }: ProfileRequirementCategoryCompletionTileProps) {
    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const { profileCompletionRequirements, calculateCategoryPercentages, isLoading } = useProfileCompletionRequirements();
    const requirementCategory = profileCompletionRequirements?.requirementCategories.find(
        (x) => x.attributeCategory === attributeCategory
    );

    if (!requirementCategory) {
        return null;
    }

    const { currentCompletion, phases } = calculateCategoryPercentages(requirementCategory);

    function renderTextBlock() {
        if (currentCompletion < phases[0].maxPercentage) {
            const remaining = Math.max(0, phases[0].maxPercentage - currentCompletion);
            return (
                <div className="profile-text mb-3">
                    <h4>{t(`dashboard:requirementCategoryCompletion:${attributeCategory}:insufficientData:title`)}</h4>
                    <p>{t(`dashboard:requirementCategoryCompletion:${attributeCategory}:insufficientData:description`, { remaining })}</p>
                </div>
            );
        } else if (currentCompletion < phases[1].maxPercentage) {
            const remaining = Math.max(0, phases[1].maxPercentage - currentCompletion);
            return (
                <div className="profile-text mb-3">
                    <h4>{t(`dashboard:requirementCategoryCompletion:${attributeCategory}:minimumRequiredData:title`)}</h4>
                    <p>{t(`dashboard:requirementCategoryCompletion:${attributeCategory}:minimumRequiredData:description`, { remaining })}</p>
                </div>
            );
        } else {
            return (
                <div className="profile-text mb-3">
                    <h4>{t(`dashboard:requirementCategoryCompletion:${attributeCategory}:completeProfile:title`)}</h4>
                    <p>{t(`dashboard:requirementCategoryCompletion:${attributeCategory}:completeProfile:description`)}</p>
                </div>
            );
        }
    }

    return (
        <DashboardTile
            tileClassName={`${tileClassName}`}
            contentClassName="profile-requirement-category-completion-tile-content"
        >
            <h2 className="title">{translateAttributeCategory(attributeCategory)}</h2>

            <div
                className={`category-progress ${
                    requirementCategory?.percentCompleted === 100 && "category-progress--completed"
                }`}
            >
                {!isLoading && (
                    <PhasedProgressBar
                        currentStep={currentCompletion}
                        maxSteps={100}
                        phases={phases}
                    />
                )}
            </div>

            <div className="info">
                {isLoading ? (
                    <div className="d-flex py-5 align-items-center justify-content-center">
                        <WihvLoader />
                    </div>
                ) : (
                    <>
                        {renderTextBlock()}

                        {requirementCategory.percentCompleted < 100 &&
                            <RequirementTaskList requirementCategory={requirementCategory} />
                        }

                        {requirementCategory.percentCompleted === 100 && (
                            <div className="completed">
                                <i className="icon fas fa-check-circle"></i>
                            </div>
                        )}
                    </>
                )}
            </div>
        </DashboardTile>
    );
}

export default ProfileRequirementCategoryCompletionTile;
