export enum MeasurementType {
    Unknown = 0,
    Form = 1,
    Matrix = 2,
    Poll = 3,
    Video = 4,
    FileUpload = 5,
    ImageSelection = 6,
    Essay = 7,
    KnowledgeQuiz = 8,
}