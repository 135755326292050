import useCompletedLearningResources from "@/hooks/useCompletedLearningResources";
import { LearningPath } from "@/learning/models/learning-path";
import { LearningResource } from "@/learning/models/learning-resource";
import { LearningResourceType } from "@/learning/models/learning-resource-type";
import { SupplierType } from "@/learning/models/supplier-type";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import FavoriteLearningResourceToggle from "../favorite-toggle/FavoriteLearningResourceToggle";
import { useSupplierTypeTranslation } from "@/general/i18n/translation-helpers";
import './LearningResourceCard.scss';

type LearningResourceCardProps = {
    resource: LearningResource;
}

function LearningResourceCard({ resource }: LearningResourceCardProps) {

    const isAuthenticated = useIsAuthenticated();
    const { completedLearningResources } = useCompletedLearningResources();
    const { translateSupplierType } = useSupplierTypeTranslation();

    return (
        <>
            {resource && resource.resourceType == LearningResourceType.LearningCourse &&
                <Link to={`/learning/course/${resource.id}`} className='learning-resource-card learning-resource-card--course'>

                    <div className="img" style={{ position: 'relative' }}>
                        <img src={resource.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} alt='' />

                        {resource.supplier != null && (
                            <div className="learning-resource__provider-logo">
                                {resource.supplier === SupplierType.LinkedInLearning && <i className="icon icon--linkedin fa-brands fa-linkedin"></i>}
                                {resource.supplier === SupplierType.Microsoft365 && <i className="icon icon--microsoft fa-brands fa-microsoft"></i>}
                                {resource.supplier === SupplierType.Unknown && <i className="icon fas fa-circle-question"></i>}
                                <span className="supplier-text">{translateSupplierType(resource.supplier)}</span>
                            </div>
                        )}
                    </div>


                    {isAuthenticated &&
                        <div className="favorite">
                            <FavoriteLearningResourceToggle resource={resource} />
                        </div>
                    }

                    <div className="banners">
                        {completedLearningResources?.some(x => x.id == resource.id) &&
                            <div className="completed">
                                <i className="fas fa-check"></i>
                            </div>
                        }
                    </div>

                    <div className='content'>
                        <h2 className='title'>{resource.name}</h2>
                        <div className="meta">
                            <span className="meta-item">{t('learning:overview:learningCourse')}</span>
                            <span className="meta-item">{resource.experienceLevel}</span>
                            <span className="meta-item">{Math.ceil(resource.durationInMinutes / 60)} {t('general:hourLong')}</span>
                            <span className="meta-item">{t(`languages:${resource.languageName}`)}</span>
                        </div>
                        <p className='description'>{resource.description}</p>
                    </div>
                </Link>
            }

            {resource && resource.resourceType == LearningResourceType.LearningPath &&
                <Link to={`/learning/path/${resource.id}`} className='learning-resource-card learning-resource-card--path'>

                    <div className="img" style={{ position: 'relative' }}>
                        <img alt='' src={resource.imageUrl || '/assets/img/WIHV_3D_Visual_Leerpad.jpg'} />

                        {resource.supplier != null && (
                            <div className="learning-resource__provider-logo">
                                {resource.supplier === SupplierType.LinkedInLearning && <i className="icon icon--linkedin fa-brands fa-linkedin"></i>}
                                {resource.supplier === SupplierType.Microsoft365 && <i className="icon icon--microsoft fa-brands fa-microsoft"></i>}
                                {resource.supplier === SupplierType.Unknown && <i className="icon fas fa-circle-question"></i>}
                                <span className="supplier-text">{translateSupplierType(resource.supplier)}</span>
                            </div>
                        )}
                    </div>
                    
                    {isAuthenticated &&
                        <div className="favorite">
                            <FavoriteLearningResourceToggle resource={resource} />
                        </div>
                    }

                    <div className="banners">
                        <div className='type'>
                            <i className="fas fa-sitemap"></i>
                        </div>

                        {completedLearningResources?.some(x => x.id == resource.id) &&
                            <div className="completed">
                                <i className="fas fa-check"></i>
                            </div>
                        }
                    </div>

                    <div className='content'>
                        <h2 className='title'>{resource.name}</h2>
                        <div className="meta">
                            <span className="meta-item">{t('learning:overview:learningPath')}</span>
                            <span className="meta-item">{resource.experienceLevel}</span>
                            <span className="meta-item">{Math.ceil(resource.durationInMinutes / 60)} {t('general:hourLong')}</span>
                            <span className="meta-item">{(resource as LearningPath).courses?.length || 0} {t('learning:overview:learningCourses')}</span>
                            <span className="meta-item">{t(`languages:${resource.languageName}`)}</span>
                        </div>
                        <p className='description'>{resource.description}</p>
                    </div>
                </Link>
            }
        </>
    )
}

export default LearningResourceCard
