import { ADErrorComponent } from "@/components/active-directory/ADErrorComponent";
import { ADLoadingComponent } from "@/components/active-directory/ADLoadingComponent";
import { ConfigContext } from "@/context/ConfigContext";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { useContext } from "react";

export const ProtectedRoute = ({ children }) => {

    var configContext = useContext(ConfigContext);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: [...configContext?.configBody?.api?.scopes] }}
            errorComponent={ADErrorComponent}
            loadingComponent={ADLoadingComponent}>
            {children}
        </MsalAuthenticationTemplate>
    )
};
