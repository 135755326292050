import DateControl, { DateControlType } from '@/components/DateControl';
import Button from '@/components/button/Button';
import { ProfileContext } from '@/context/ProfileContext';
import { PROFILE_DATE_FORMAT } from '@/general/constants';
import { useMissingProfileCardRequirements } from '@/hooks/useMissingProfileCardRequirements';
import { Certificate } from '@/profile/model/certificate';
import { Profile } from '@/profile/model/profile';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { createRef, useContext, useState } from 'react';
import { Card, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import './ProfileCard.scss';

function ProfileCertificates() {

    const profileContext = useContext(ProfileContext);
    const [showEditModal, setShowEditModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [noCertificates, setNoCertificates] = useState(false);
    const formRef = createRef<HTMLFormElement>();
    const { totalRequirementsCount, completedRequirementsCount, isRequired } = useMissingProfileCardRequirements(['certificates']);

    const openModal = () => {
        setValidated(false);
        setNoCertificates(profileContext.profile?.hasNoCertificates ?? false);
        setShowEditModal(true);
    }

    const closeModal = () => {
        setShowEditModal(false);
        profileContext.updateChanges(profileContext.profile);
    };

    const saveChanges = () => {
        setValidated(true);
        const updatedProfile = {
            ...profileContext.tempChanges,
            hasNoCertificates: noCertificates,
            certificates: noCertificates ? [] : profileContext.tempChanges.certificates
        };

        if (formRef.current?.checkValidity()) {
            profileContext.saveProfile(updatedProfile)
                .then(() => { closeModal(); })
                .catch(() => { });
        }
    };

    const updateTempChanges = (index, propertyName, newValue) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            certificates: profileContext.tempChanges.certificates.map((item, i) => i === index ? { ...item, [propertyName]: newValue } : item)
        };

        profileContext.updateChanges(updatedProfile);
    };

    const addCertificate = () => {
        const updatedCertificates = [...profileContext.tempChanges.certificates, {} as Certificate];
        profileContext.updateChanges({ ...profileContext.tempChanges, certificates: updatedCertificates });
    }

    const removeCertificate = (index: number) => {
        const updatedCertificates = profileContext.tempChanges.certificates.filter((item, i) => i !== index);
        profileContext.updateChanges({ ...profileContext.tempChanges, certificates: updatedCertificates });
    }

    return (
        <>
            <Card className="profile-card mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:certificates:title')}</Card.Title>
                    <motion.button whileTap={{ scale: 0.85 }} onClick={() => openModal()}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {profileContext.profile?.hasNoCertificates ? (
                            <div className='card-item'>
                                <p>{t('profile:certificates:noCertificatesSelected')}</p>
                            </div>
                        ) : (
                            <>
                                {profileContext.profile?.certificates?.map((item, i) => (
                                    <div className='card-item' key={i}>
                                        <h3 className='title'>{item.name}</h3>
                                        <p className='subtitle'>{item.issuer}</p>
                                        <p className='time-period'>{dayjs(item.achieved).format(PROFILE_DATE_FORMAT)}</p>
                                    </div>
                                ))}

                                {profileContext.profile?.certificates?.length === 0 &&
                                    <>
                                        {isRequired
                                            ? <div className='profile-completion-required'>
                                                <p>{t('profile:general:missingRequirements')}</p>
                                                <i className="icon fa-solid fa-arrow-turn-up"></i>
                                            </div>
                                            :
                                            <div className='card-item'>
                                                <p>{t('profile:certificates:noCertificates')}</p>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        )}
                    </Card.Text >
                </Card.Body>
            </Card>

            <Modal className='wihv-modal wihv-modal--profile' size='lg' show={showEditModal} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('profile:certificates:title')}</Modal.Title>
                    <CloseButton variant='white' onClick={() => closeModal()} />
                </Modal.Header>
                <Modal.Body>
                    <Form ref={formRef} validated={validated} noValidate>
                        <Form.Group controlId="noCertificatesCheckbox" className="mb-3">
                            <Form.Check 
                                type="checkbox"
                                label={t('profile:certificates:noCertificatesOption')}
                                checked={noCertificates}
                                onChange={e => {
                                    const checked = e.target.checked;
                                    setNoCertificates(checked);
                                    profileContext.updateChanges({ 
                                        ...profileContext.tempChanges,
                                        hasNoCertificates: checked,
                                        certificates: checked ? [] : profileContext.tempChanges.certificates
                                    });
                                }}
                            />
                        </Form.Group>

                        { noCertificates ? (
                            <div className='text-center'>
                                <p>{t('profile:certificates:noCertificatesSelected')}</p>
                            </div>
                        ) : (
                            profileContext.tempChanges?.certificates?.length === 0 ? (
                                <div className='text-center'>
                                    <p>{t('profile:certificates:noCertificates')}</p>
                                </div>
                            ) : (
                                profileContext.tempChanges?.certificates?.map((item, index) => (
                                    <Row className='wihv-modal__row p-2 p-md-3' key={index}>
                                        <Col sm={12} className="p-0">
                                            <Form.Group as={Row} className="mt-0">
                                                <Col className="mt-2">
                                                    <Form.Label><span>{t('profile:certificates:labelName')}</span><span className='required'>*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="" value={item.name ?? ''} onChange={e => updateTempChanges(index, 'name', e.target.value)} required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mt-0">
                                                <Col className="mt-2">
                                                    <Form.Label><span>{t('profile:certificates:labelIssuer')}</span></Form.Label>
                                                    <Form.Control type="text" placeholder="" value={item.issuer ?? ''} onChange={e => updateTempChanges(index, 'issuer', e.target.value)} required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mt-0">
                                                <Col className="mt-2">
                                                    <Form.Label>{t('profile:certificates:labelAchieved')}</Form.Label>
                                                    <DateControl controlType={DateControlType.MonthAndYear} value={item.achieved?.toString()} onChange={e => updateTempChanges(index, 'achieved', e.target.value)} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mt-0">
                                                <Col className="mt-2">
                                                    <Form.Label>{t('profile:certificates:validUntil')}</Form.Label>
                                                    <DateControl
                                                        controlType={DateControlType.MonthAndYear}
                                                        minValue={item.validUntil?.toString()}
                                                        value={item.validUntil?.toString()}
                                                        onChange={(e) => updateTempChanges(index, 'to', e.target.value)}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <button type='button' onClick={() => removeCertificate(index)} className="wihv-modal__row-delete"><i className="fa-solid fa-trash-can" /></button>
                                    </Row>
                                ))
                            )
                        )}
                    </Form>
                    { noCertificates != true &&
                        <Row>
                            <Col sm={12} className='mt-3 text-center'>
                                <Button displayType='secondary' text={t('profile:certificates:addCertificateBtn')} icon='plus' onClick={() => addCertificate()} />
                            </Col>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button displayType='secondary' text={t('general:cancel')} onClick={() => closeModal()} />
                    <Button displayType='primary' text={t('general:save')} loading={profileContext.requestInProgress} onClick={() => saveChanges()} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileCertificates
